<template>
  <div v-if="list.length > 0">
    <h5 class="tw-text-lg tw-font-semibold">Layanan</h5>
    <hr class="mt-2 mb-4" />
    <div>
      <DataTable
        :value="list"
        :paginator="false"
        responsiveLayout="scroll"
        :rowHover="false"
        @page="currentPage($event)"
      >
        <Column header="No">
          <template #body="{ index }">
            {{ index + 1 }}
          </template>
        </Column>
        <Column field="service_name" header="Layanan"></Column>
        <Column field="barber_name" header="Nama Pegawai"></Column>
        <Column header="Tanggal">
          <template #body="{ data }">
            {{ displayStartEnd(data.start, data.end) }}
          </template>
        </Column>
        <Column field="status" header="Status">
          <template #body="slotProps">
            <div v-if="slotProps.data.status === 0">
              <Dropdown
                placeholder="Update"
                option-label="name"
                option-value="code"
                v-model="slotProps.data.updated_status"
                :options="actionOptions"
                @change="(e) => updateStatus(slotProps.index, e)"
              />
              <div class="tw-text-red-500" v-if="slotProps.data.isError">
                * Status harus diisi terlebih dahulu
              </div>
            </div>

            <Tag
              :severity="severity(slotProps.data.status)"
              :value="bookingStatus.find((e) => e.id === slotProps.data.status).value"
              v-else>
            </Tag>
          </template>
        </Column>
        <Column field="status" header="Aksi">
          <template #body="slotProps">
            <Button
              class="tw-text-sm px-3 py-2"
              :disabled="loadings[slotProps.index]"
              @click="changeBookingStatus(slotProps.index, slotProps.data, 4)"
              v-if="slotProps.data.status === 1"
            >
              <span v-if="!loadings[slotProps.index]">Dikerjakan</span>
              <i class="pi pi-spin pi-spinner" v-else></i>
            </Button>

            <Button
              class="tw-text-sm px-3 py-2"
              :disabled="loadings[slotProps.index]"
              @click="changeBookingStatus(slotProps.index, slotProps.data, 5)"
              v-if="slotProps.data.status === 4"
            >
              <span v-if="!loadings[slotProps.index]">Selesai</span>
              <i class="pi pi-spin pi-spinner" v-else></i>
            </Button>
          </template>
        </Column>
      </DataTable>
    </div>

    <div class="mt-3 tw-flex tw-justify-end" v-if="invoice.status === 0">
      <Button label="Submit" class="px-5" @click="onSubmit(props.invoiceId)" />
    </div>
  </div>
</template>

<script setup>
import {
  defineProps,
  defineEmits,
  onBeforeMount,
  onMounted,
  watch,
  ref,
} from "vue";
import { useBookings, generatePayment } from "@/composables/invoices";
import moment from "moment";

const {
  list,
  statusUpdated,
  STATUS_APPROVE,
  STATUS_DENY,
  getList,
  updateBookingsStatus,
  updateBookingStatus,
} = useBookings();

const { qris, getQRIS, generateQRIS } = generatePayment();

const emit = defineEmits(["getQRIS", "updateInvoice"]);

const props = defineProps({
  invoiceId: String,
  invoice: Object,
});

const loadings = ref([]);

const actionOptions = [
  { name: "Disetujui", code: STATUS_APPROVE },
  { name: "Ditolak", code: STATUS_DENY },
];

const bookingStatus = [
  { id: 0, value: "Menunggu" },
  { id: 1, value: "Disetujui" },
  { id: 2, value: "Dibatalkan admin" },
  { id: 3, value: "Dibatalkan customer" },
  { id: 4, value: "Berlangsung" },
  { id: 5, value: "Selesai" },
];

const displayStartEnd = (start, end) => {
  const date = moment(start).format("dddd, DD MMMM YYYY | HH:mm");
  const endTime = moment(end).format("HH:MM");

  return `${date} - ${endTime}`;
};

const updateStatus = (index, { value }) => {
  const bookingId = list.value[index].id;
  list.value[index].updated_status_id = value;
  list.value[index].updated_status_name = actionOptions
    .filter((action) => action.code === value)
    .map((item) => item.name)
    .toString();

  const objIndex = statusUpdated.value.findIndex(
    (obj) => obj.booking_id === bookingId
  );

  if (objIndex !== -1) {
    statusUpdated.value[objIndex].status = value;
  } else {
    statusUpdated.value.push({ booking_id: bookingId, status: value });
  }

  list.value[index].isError = false;
};

const severity = (status) => {
  let result = "";
  switch (status) {
    case 0:
    case 4:
      result = "warning";
      break;
    case 1:
      result = "info";
      break;
    case 5:
      result = "success";
      break;
    default:
      result = "danger";
      break;
  }
  return result;
};

const onSubmit = async (invoiceId) => {
  console.log(invoiceId);
  // await generateQRIS(props.invoice.id, props.invoice.invoice_number)

  // console.log('QRIS', qris.value);
  // emit('getQRIS', qris);

  let isValid = true;
  let arr = list.value;

  for (let item of arr) {
    const objIndex = statusUpdated.value.findIndex(
      (obj) => obj.booking_id === item.id
    );
    if (objIndex !== -1) {
      isValid &= true;
      item.isError = false;
    } else {
      isValid &= false;
      item.isError = true;
    }
  }

  if (isValid) {
    await updateBookingsStatus(invoiceId);

    /**
     * will change with
     * await generateQRIS(props.invoice.id, props.invoice.invoice_number)
     */
    // await getQRIS(props.invoice.id, props.invoice.invoice_number);
    await generateQRIS(props.invoice.id, props.invoice.invoice_number);
    emit("getQRIS", qris);

    let inv = props.invoice;
    inv.status = 1;
    emit("updateInvoice", inv);

    for (let item of arr) {
      item.status = item.updated_status_id;
    }
  }
};

const changeBookingStatus = async (index, data, status) => {
  loadings.value[index] = true;
  let resStatus = await updateBookingStatus(data.id);

  loadings.value[index] = false;
  if (resStatus !== 200) return;

  list.value[index].status = status;
};

onBeforeMount(async () => {
  await getList(props.invoiceId);

  for (let i = 0; i < list.value.length; i++) {
    loadings.value[i] = false;
  }
});

onMounted(async () => {
  if (props.invoice.invoice_number && props.invoice.status !== 0) {
    await getQRIS(props.invoice.id, props.invoice.invoice_number);
    emit("getQRIS", qris);
  }
});

watch(
  () => props.invoice,
  async (invoice) => {
    if (invoice.status !== 0) {
      await getQRIS(props.invoice.id, props.invoice.invoice_number);
      emit("getQRIS", qris);
    }
  }
);
</script>

<style scoped></style>
