<template>
  <router-link
    :to="{ name: 'invoices' }"
    class="block mb-3 text-lg tw-font-semibold tw-text-blue-700 tw-hover:tx-text-blue-900"
  >
    <i class="mr-2 pi pi-arrow-left"></i>
    Kembali
  </router-link>

  <div class="mb-5 card">
    <h1 class="tw-text-xl tw-font-bold tw-mb-8">Detail Pesanan</h1>
    <div class="grid mb-5">
      <div class="col-12 md:col-4">
        <h3 class="mb-3 tw-text-lg tw-font-semibold">QR Code</h3>
        <div  class="p-2 overflow-hidden border-1 border-300 border-round tw-inline-block tw-mb-2" v-if="!qris.qrcode">
          <QRCodeVue3
            :value="'QR Code will be generated after update services status'"
            :dotsOptions="{ type: 'rounded', color: '#dedede' }"
            :cornersSquareOptions="{ type: 'square', color: '#dedede' }"
            :cornersDotOptions="{ type: undefined, color: '#dedede' }"
            fileExt="png"
          />
        </div>
        <div class="overflow-hidden border-1 border-300 border-round tw-inline-block tw-mb-2" v-else>
          <QRCodeVue3
            :value="qris.qrcode"
            :dotsOptions="{ type: 'rounded', color: '#000000' }"
            :cornersSquareOptions="{ type: 'square', color: '#000000' }"
            fileExt="png"
          />
        </div>
      </div>

      <div class="col-0 md:col-1"></div>
      <div class="col-12 md:col-7">
        <h5 class="tw-text-lg tw-font-semibold">Detail</h5>
        <hr class="mt-2 mb-4">
        <div class="grid mb-2" v-for="column in columns" :key="`_${column.field}`">
          <div class="col-6 md:col-4 opacity-60">
            {{ column.header }}
          </div>
          <div class="col-6 md:col-8 tw-font-medium">
            <div v-if="!column?.process">
              {{ column.field === 'amount' 
                  ? formatCurrency(data[column.field])
                  : column.field === 'status'
                    ? status[data[column.field]]
                    : data[column.field] }}
            </div>
            <div v-else>{{ column.process(data[column.field]) }}</div>
          </div>
        </div>

        <div class="grid align-items-center">
          <div class="col-6 md:col-4 opacity-60">
            Biaya Operasional
          </div>
          <div class="col-6 md:col-8">
            <div class="flex align-items-center" v-if="!isEditOperationalFee">
              <div class="mr-3">
                {{ formatCurrency(data['operational_fee']) }}
              </div>
              <div v-if="data.status === 0">
                <Button
                  icon="pi pi-pencil"
                  class="px-2 py-1 mb-0 p-button-warning"
                  label="edit"
                  @click="toggleEditOperationalFee"
                />
              </div>
            </div>

            <div v-else class="tw-flex tw-justify-between tw-items-center">
              <div>
                <InputNumber
                  v-model="operationalFee"
                  mode="currency"
                  currency="IDR"
                  locale="en-US"
                />
              </div>
              <div class="tw-flex">
                <Button
                  icon="pi pi-check"
                  class="p-button-rounded p-button-success"
                  @click="submitOperationalFee"
                />
                <Button
                  icon="pi pi-times"
                  class="p-button-rounded p-button-danger"
                  @click="toggleEditOperationalFee"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mb-5">
      <BookingList
        :invoice-id="route.params.id"
        :invoice="data"
        @getQRIS="getQRIS($event)"
        @updateInvoice="updateInvoice($event)"
      />
    </div>
  </div>
</template>

<script setup>
import { useRoute } from 'vue-router';
import { onBeforeMount, ref } from 'vue';

import { useInvoiceDetail } from '@/composables/invoices';
import BookingList from './components/BookingList';
import { useCommonUtils } from '@/composables/utils';
import QRCodeVue3 from 'qrcode-vue3';

const route = useRoute();

const { data, getDetail, editOperationalFee } = useInvoiceDetail();
const { formatCurrency } = useCommonUtils();

const isEditOperationalFee = ref(false);
const operationalFee = ref(0);

const toggleEditOperationalFee = () => {
  isEditOperationalFee.value = !isEditOperationalFee.value;
  operationalFee.value = data.value['operational_fee'];
};

const submitOperationalFee = async () => {
  await editOperationalFee(route.params.id, operationalFee.value);
  toggleEditOperationalFee();
};

const columns = [
  { field: 'salon_name', header: 'Nama Salon' },
  { field: 'customer_name', header: 'Nama Customer' },
  { field: 'invoice_number', header: 'No. Invoice' },
  { field: 'amount', header: 'Harga' },
  { field: 'status', header: 'Status' },
];

const qris = ref({});

const getQRIS = (event) => {
  qris.value = event.value;
};

const updateInvoice = (value) => {
  data.value = value;
};

const status = {
  0: 'Menunggu diprosees',
  1: 'Belum dibayar',
  2: 'Sudah dibayar',
  3: 'Dibatalkan',
}

onBeforeMount(async () => {
  await getDetail(route.params.id);
  operationalFee.value = data.value['operational_fee'];
});
</script>

<style scoped></style>
